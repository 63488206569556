import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { texts } from '../assets/content/localisation';
import { textsList } from '../assets/content/texts';
import { Link } from 'react-router-dom';

export default function Events() {
    const { language } = useContext(LanguageContext);

    const createList = (items) => {
        return items.map((item) => (
            <p key={item.url}>
                <a href={item.url} target="_blank" rel="noreferrer">
                    {item.title}
                </a>
            </p>
        ));
    }

    return (
        <section id="texts">
            {textsList.articles.length !== 0 && <h3>{texts[language].articles}</h3>}
            {textsList.articles.length !== 0 && createList(textsList.articles)}

            {textsList.reviews.length !== 0 && <h3>{texts[language].reviews}</h3>}
            {textsList.reviews.length !== 0 && createList(textsList.reviews)}

            {textsList.dramas.length !== 0 && <h3>{texts[language].dramas}</h3>}
            {textsList.dramas.length !== 0 && textsList.dramas.map((item) => (
                <p key={item.title}>
                    <Link to={`/texts/${item.id}`}>{item.title}</Link>
                </p>
            ))}

            {textsList.prose.length !== 0 && <h3>{texts[language].prose}</h3>}
            {textsList.prose.length !== 0 && createList(textsList.prose)}
        </section>
    );
}
