import { AiOutlineInstagram, AiOutlineLink } from 'react-icons/ai';
import { FaYoutube, FaFacebook, FaBook, FaSpotify } from 'react-icons/fa6';
import { MdOndemandVideo } from "react-icons/md";

function getIcon(type) {
    switch (type) {
        case 'instagram':   return <AiOutlineInstagram />;
        case 'facebook':    return <FaFacebook />;
        case 'youtube':     return <FaYoutube />;
        case 'spotify':     return <FaSpotify />;
        case 'book':        return <FaBook />;
        case 'video':       return <MdOndemandVideo />;
        default:            return <AiOutlineLink />;
    }
}

function getIconHoverColor(type) {
    switch (type) {
        case 'instagram':   return '#c13584';
        case 'facebook':    return '#4267b2';
        case 'youtube':     return '#ff0000';
        case 'spotify':     return '#1db954';
        case 'book':
        case 'video':
        default:            return '#c64191';
    }
}

export default function LinkedIconList({ items }) {
    return (
        <ul className="linked-icon-list">
            {items.map((item) => (
                <li key={item.type} style={{ '--icon-hover-color': getIconHoverColor(item.type) }}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        {getIcon(item.type)}
                    </a>
                </li>
            ))}
        </ul>
    );
}
