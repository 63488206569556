export const textsList = {
    articles: [
        {
            title: 'Jak odkryłem, że wszystko jest łańcuszkiem szczęścia',
            id: 'jak-odkrylem',
            url: 'https://www.stanrzeczy.edu.pl/index.php/srz/article/view/404/369',
        },
    ],
    reviews: [
        {
            title: 'Ciemno, coraz ciemniej. Po lekturze „Ciemniejącego wieku” Catherine Nixey',
            id: 'ciemno-coraz-ciemniej',
            url: 'https://stonerpolski.pl/ciemno-coraz-ciemniej-po-lekturze-ciemniejacego-wieku-catherine-nixey/',
        },
        {
            title: 'Ukłon zabawowy. Eva Meijer – „Zwierzęta mówią”',
            id: 'uklon-zabawowy',
            url: 'https://stonerpolski.pl/faza-biezaca-uklon-zabawowy-eva-meijer-zwierzeta-mowia/',
        },
        {
            title: 'Pogrążeni w „Chamstwie”. O książce Kacpra Pobłockiego',
            id: 'pograzeni-w-chamstwie',
            url: 'https://stonerpolski.pl/faza-biezaca-pograzeni-w-chamstwie-o-ksiazce-kacpra-poblockiego/',
        },
    ],
    dramas: [],
    prose: [
        {
            title: 'Sztuka umierania kwiatów (fragmenty powieści)',
            id: 'sztuka-umierania-kwiatow-fragmenty',
            url: 'https://magazynwizje.pl/skurzynski-proza/',
        },
    ],
};
