import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { events } from '../assets/content/localisation';
import { eventList } from '../assets/content/events';
import EventTiles from '../components/event-tiles';

const now = new Date();

export default function Events() {
    const { language } = useContext(LanguageContext);

    const upcomingEvents = eventList
        .filter((event) => event.date.end > now)
        .sort((a, b) => (a.date.start > b.date.start ? 1 : b.date.start > a.date.start ? -1 : 0));
    const pastEvents = eventList
        .filter((event) => event.date.end <= now)
        .sort((a, b) => (a.date.start < b.date.start ? 1 : b.date.start < a.date.start ? -1 : 0));

    return (
        <section id="events">
            <h3>{events[language].upcoming}</h3>
            <EventTiles eventsList={upcomingEvents} />
            <h3>{events[language].past}</h3>
            <EventTiles eventsList={pastEvents} />
        </section>
    );
}
