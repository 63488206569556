import { useContext } from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

export default function Book() {
    const { books } = useOutletContext();
    const { language } = useContext(LanguageContext);

    let { bookId } = useParams();
    let book = books.find((item) => item.id === bookId);

    return book ? (
        <section id="book">
            <h3>{book.title}</h3>
            <div className="book">
                <div className="book-overview">
                    <div className="book-cover">
                        <img
                            src={require('../assets/images/' + book.id + '-thumb.png')}
                            alt={`${book.title}`}
                        />
                    </div>
                    <table className="book-details">
                        <tbody>
                            {book.details[language].map((row) => (
                                <tr key={row.label}>
                                    <th>{row.label}</th>
                                    <td>{row.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="quote">
                    {book.quote.map((p, i) => (
                        book.isQuoteStyled ?
                            <p key={i} style={p.style}>
                                {p.content.map((t, j) => (
                                    <span key={`${i}.${j}`} style={t.style}>{t.text}</span>
                                ))}
                            </p> : 
                            <p key={i}>{p}</p>
                    ))}
                </div>
            </div>
        </section>
    ) : (
        <Navigate to="/" replace />
    );
}
